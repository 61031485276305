// import { useLanguage } from './LanguageContext';

function Showreelsm() {
    // return (
    //     <div className="showreel-sm">
    //         <section id="about-me-heading">
    //             <h1>Videobook</h1>
    //         </section>
    //         <section id="about-me-sm">
    //             <iframe width="350" height="197" src="https://youtu.be/LYA18QeJK8Y?si=2tC64LnUCSWjyPU3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    //         </section>
    //     </div>
    // );
    // const { language } = useLanguage();

    // let showreel = "";

    // if (language === "EN") {
    //     showreel = "Showreel";
    // } else if (language === "EU") {
    //     showreel = "Bideobook-a";
    // } else {
    //     showreel = "Videobook";
    // }

    return (
        <div className="showreel-sm">
            {/* <section id="video-heading-sm">
                <h1>{showreel}</h1>
            </section> */}
            <section id="video-sm">
                <iframe width="350" height="197" src="https://www.youtube.com/embed/LYA18QeJK8Y?si=nlOwlEJo07zb-xOn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </section>
        </div>
    );
}

export default Showreelsm;